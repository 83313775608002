import { useQuery } from "urql";
import {
  StylingModelsQueryDocument,
  StylingModelsQueryVariables
} from "../graphql/StylingModelsQuery";

export const useStylingModels = (variables: StylingModelsQueryVariables = {}) => {
  const [result] = useQuery({
    query: StylingModelsQueryDocument,
    variables: variables
  })
  return result
}
